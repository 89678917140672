// import { mapState } from 'vuex';
import KnBackToTopButton from '../../../shared/components/KnBackToTopButton.vue';
import KnFormActionButtons from '../../../shared/components/KnFormActionButtons/KnFormActionButtons.vue';
import KnFormTitle from '../../../shared/components/KnFormTitle.vue';
import KnLocalAlert from '../../../shared/components/KnLocalAlert.vue';
import KnPagination from '../../../shared/components/KnPagination.vue';
import {
  fetchEvaluationPeriods,
  fetchSubjectStudents,
  getNameOfSubjectHolder,
} from '../../helpers/syllabusOptions';
import KnAuthorizationGradeItem from '../KnAuthorizationGradeItem/KnAuthorizationGradeItem.vue';
import KnAuthorizationGradeDetail from '../KAuthorizationGradeDetail/KnAuthorizationGradeDetail.vue';

import { paginationMixin } from '../../../shared/mixins/paginationMixin';
import {
  fetchGrades,
  fetchGradeDetails,
  GradeActions,
  updateGradeStatus,
} from '../../helpers/gradeOptions';
import { fetchQualificationStatus } from '../../../configuration/helpers/KnGroupsOptions';
import { mapState } from 'vuex';
export default {
  name: 'KnAuthorizationGradesPerSubject',
  components: {
    KnAuthorizationGradeDetail,
    KnAuthorizationGradeItem,
    KnBackToTopButton,
    KnFormActionButtons,
    KnFormTitle,
    KnLocalAlert,
    KnPagination,
  },
  mixins: [paginationMixin],
  props: {
    entity: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      bkEntity: null,
      bkReadonly: false,
      valueDeterminate: 100,
      routerName: 'Autorizar Calificaciones',
      valid: true,
      grade: null,
      gradeDetails: [],
      gradeData: null,
      subjectStudents: [],
      /** Variables para alerta */
      errors: [],
      warnings: [],
      loading: false,
      showAlert: false,
      alertType: 'success',
      alertText: 'Registro exitoso',
      alertColor: null,
      /*********************** */
      loadingDetails: false,
      showDetail: false,
      gradeStatus: null,
      selectedEvaluationPeriod: null,
      periods: [],
      showEvaluationPeriod: true,
    };
  },
  computed: {
    ...mapState(['institutionId']),
    isNewMode() {
      return this.entity === null;
    },
    subjectName() {
      return !this.isNewMode ? this.entity.nombre : '';
    },
    periodFilter() {
      return !this.isNewMode
        ? this.entity && this.entity.evaluationPeriodFilter
          ? this.entity.evaluationPeriodFilter
          : null
        : null;
    },
    schoolYearFilter() {
      return !this.isNewMode
        ? this.entity.syllabusFilter
          ? this.entity.syllabusFilter.ciclo_escolar.dato
          : this.entity.schoolYearFilter
          ? this.entity.schoolYearFilter.dato
          : ''
        : '';
    },
    schoolYear() {
      return !this.isNewMode
        ? this.entity.syllabusFilter
          ? this.entity.syllabusFilter.ciclo_escolar
          : this.entity.schoolYearFilter
          ? this.entity.schoolYearFilter
          : null
        : null;
    },
  },
  async created() {
    this.setPaginationLimit(30);
    this.loading = true;
    this.alertText = 'Cargando... Por favor, espera';
    this.alertType = 'info';
    this.openAlert();
    // await this.getPaginatedSubjectStudent(1);
    await this.setEvaluationPeriods();
    this.closeAlert();
    this.loading = false;
  },
  methods: {
    async fetchData() {
      // if (!this.isNewMode) {
      //   const { ok, data, message } = await fetchSubjectStudents({
      //     sub
      //   })
      // }
    },
    async setEvaluationPeriods() {
      if (this.showEvaluationPeriod) {
        const { ok: okEvaluationPeriod, data: dataEvaluationPeriod } =
          await fetchEvaluationPeriods({
            institutionId: this.institutionId,
            systemStatus: true,
            startDate: this.schoolYear ? this.schoolYear.fecha_inicio : null,
            endDate: this.schoolYear ? this.schoolYear.fecha_fin : null,
            limit: 100,
          });

        this.periods = okEvaluationPeriod ? dataEvaluationPeriod : [];
      }
    },
    async getPaginatedSubjectStudent(page = 1) {
      // console.log('PeriodoFilter', this.periodFilter);
      if (!this.isNewMode) {
        this.setPaginationPage(page);
        if (!this.loading && this.selectedEvaluationPeriod) {
          this.subjectStudents = [];
          this.loading = true;
          const { ok, data, message, count } = await fetchSubjectStudents({
            subjectId: this.entity.id,
            systemStatus: true,
            limit: this.pagination.limit,
            offset: this.pagination.offset,
          });
          if (ok) {
            this.setPaginationCount(count);
            for (const subjectstudent of data) {
              // console.log('subjectstudent', subjectstudent);
              const dataPersonalData = await getNameOfSubjectHolder(
                subjectstudent.alumno
              );
              // console.log('dataPersonalData', dataPersonalData);
              subjectstudent.alumno.datos_personales = dataPersonalData;

              const { ok: okGrade, data: dataGrade } = await fetchGrades({
                institutionId: this.institutionId,
                materiaAlumno: subjectstudent.id,
                periodo: this.selectedEvaluationPeriod
                  ? this.selectedEvaluationPeriod.id
                  : null,
                systemStatus: true,
              });
              if (okGrade) {
                subjectstudent.grade = dataGrade.length ? dataGrade[0] : null;
              }

              this.subjectStudents.push(subjectstudent);
            }
          } else {
            console.log('Error al obtener materiasAlumno', message);
          }
          this.loading = false;
        } else {
          this.subjectStudents = [];
        }
      }
    },
    async getGradeDetails(gradeId) {
      const { ok, data } = await fetchGradeDetails({
        gradeId: gradeId,
        systemStatus: true,
        limit: 50,
      });
      // console.log('');
      if (ok) {
        return data;
      }
      return [];
    },
    async getGradeStatus(name) {
      const { ok, data } = await fetchQualificationStatus({
        dato: name,
        systemStatus: true,
        institutionId: this.institutionId,
      });
      if (!ok) return null;
      return data.length ? data[0] : null;
    },
    async runGradeAction(gradeData) {
      // console.log('informacion de calificacion', gradeData);
      this.alertText = 'Cambiando estatus. Por favor, espera';
      this.alertType = 'info';
      // this.loading = true;
      this.openAlert();
      // this.gradeData = gradeData;
      this.grade = gradeData;
      this.loadingDetails = true;
      let gradeStatus = null;
      switch (gradeData.action) {
        case GradeActions.PAUSE:
          gradeStatus = await this.getGradeStatus('revisión');
          break;
        case GradeActions.ACCEPT:
          gradeStatus = await this.getGradeStatus('aprob');
          break;
        case GradeActions.REJECT:
          gradeStatus = await this.getGradeStatus('rechaz');
          break;
        default:
          this.errors.push('Acción no permitida');
          // console.log('Accion no permitida');
      }
      if (gradeStatus) {
        const { ok, message } = await updateGradeStatus(
          this.grade.id,
          gradeStatus.id
        );
        if (ok) {
          // console.log(
          //   'Estatus de calificación actualizada exitosamente.',
          //   data
          // );
          await this.getPaginatedSubjectStudent(1);
        } else {
          // console.log('Error al actualizar estatus de calificación. ', message);
          this.errors.push(message);
        }
      } else {
        this.errors.push('No se pudo encontrar el estatus de calificación');
      }
      if (this.errors.length) {
        this.alertType = 'error';
        this.alertText = this.errors.join(', ');
      } else {
        this.alertType = 'success';
        this.alertText = 'Estatus de calificación actualizada exitosamente';
      }
      // this.loading = false;
      this.loadingDetails = false;
    },
    async loadDetail(gradeData) {
      // console.log('informacion de calificacion', gradeData);
      this.loadingDetails = true;
      this.openDetail();
      this.grade = gradeData;
      this.gradeDetails = await this.getGradeDetails(this.grade.id);
      this.loadingDetails = false;
    },
    async toEdit(/*gradeData*/) {
      // console.log(gradeData);
    },
    openDetail() {
      this.showDetail = true;
    },
    closeDetail() {
      this.showDetail = false;
    },
    cancel() {
      this.$router.push({ name: this.routerName });
    },
    actionAlertBtn1() {
      if (this.alertType === 'success' || this.alertType === 'info') {
        this.returnToTable();
      } else {
        this.closeAlert();
      }
    },
    continueAdding() {
      this.closeAlert();
    },
    returnToTable() {
      this.$router.replace({ name: this.routerName });
    },
    openAlert() {
      this.showAlert = true;
    },
    closeAlert() {
      this.errors = [];
      this.showAlert = false;
    },
  },
};
